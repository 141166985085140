import { useState } from 'react';
import axios from 'axios';

const ContactUs = () => {
    const initialState = { name: '', email: '', number: '', inquiry: '', };
    const [form, setForm] = useState(initialState);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailData = {
            name: form.name,
            email: form.email,
            message: form.inquiry,
            number: form.number
        }
        await axios.post('https://us-central1-jemak-inv.cloudfunctions.net/submit', emailData)
        setForm(initialState);
        
        
        
    }

    return (
        <section id={ "contact" } className="flex flex-col items-center justify-center w-screen mt-10 md:my-0 py-10">
            <h1 className="p-2 text-jemak-500 font-jemak text-2xl md:text-4xl">Contact Us</h1>

            <form className="flex flex-col justify-center items-center form-container w-[80%] md:w-[90%] rounded-2xl" onSubmit={handleSubmit}>
                <input type="text" className="mt-10 outline-none w-[90%] rounded-full p-4 float placeholder:ml-10 font-jemak" required placeholder='Name *' value={form.name} onChange={(e) => setForm({...form, name:e.target.value})}/>
                <input type="email" className="mt-10 outline-none w-[90%] rounded-full p-4 float placeholder:ml-10 font-jemak" required placeholder='Email *' value={form.email} onChange={(e) => setForm({...form, email:e.target.value})}/>
                <input type="tel" className="mt-10 outline-none w-[90%] rounded-full p-4 float placeholder:ml-10 font-jemak" placeholder='Phone Number' value={form.number} onChange={(e) => setForm({...form, number:e.target.value})}/>
                <textarea type="text" className="mt-10 outline-none w-[90%] h-64 rounded-2xl p-4 float placeholder:ml-10 font-jemak" required placeholder='Inquiry *' value={form.inquiry} onChange={(e) => setForm({...form, inquiry:e.target.value})}/>
                <button type='submit' className="my-10 p-4 text-xl bg-jemak font-jemak rounded-full w-48 text-center cursor-pointer">
                    Submit
                </button>

            </form>
        </section>
    );
};

export default ContactUs;
